<template>
  <component :is="iconName"/>
</template>

<script>
import ActiveHomeIcon from './NavigationIcons/HomeIcon/ActiveHomeIcon';
import HomeIconOne from './NavigationIcons/HomeIcon/HomeIcon';
import PhoneIcon from './PhoneIcon';
import AgreementIcon from "@/components/Icons/NavigationIcons/AgreementIcon/AgreementIcon";
import ActiveAgreementIcon from "@/components/Icons/NavigationIcons/AgreementIcon/ActiveAgreementIcon";
import MenuIcon from "@/components/Icons/NavigationIcons/MenuIcon/MenuIcon";
import ActiveMenuIcon from "@/components/Icons/NavigationIcons/MenuIcon/ActiveMenuIcon";
import ChatIcon from "@/components/Icons/NavigationIcons/ChatIcon/ChatIcon";
import HomeBankIcon from "@/components/Icons/NavigationIcons/HomeBankIcon";
import ActiveChatIcon from "@/components/Icons/NavigationIcons/ChatIcon/ActiveChatIcon";
import HomeIconTwo from "@/components/Icons/ServicesIcons/HomeIcon";
import CarIcon from "@/components/Icons/ServicesIcons/CarIcon";
import PersonIcon from "@/components/Icons/ServicesIcons/PersonIcon";
import AirplaneIcon from "@/components/Icons/ServicesIcons/AirplaneIcon";
import DownloadIcon from './DownloadIcon';
import BackArrowIcon from './BackArrowIcon';
import PlusIcon from './PlusIcon';
import SelectArrowIcon from "./SelectArrowIcon";
import CloseButtonIcon from "./CloseButtonIcon";
import CloseModalIcon from "./CloseModalIcon";
import CalendarIcon from "./CalendarIcon";
import MasterCardIcon from "./MasterCardIcon";
import SuccessIcon from "./SuccessIcon";
import RatingStar from "./RatingStar";
import UmbrellaIcon from "./ServicesIcons/UmbrellaIcon";
import InfoIcon from "./ServicesIcons/InfoIcon";
import GiftIcon from "./GiftIcon";
import BonusIcon from "./BonusIcon";
import WhatsappIcon from "./WhatsappIcon";
import MessageIcon from "./MessageIcon";
import ContractIcon from "./ContractIcon";
import HeartIcon from "./HeartIcon";
import ClipIcon from "./ClipIcon";
import BookIcon from "./BookIcon";
import SendMessageIcon from "./SendMessageIcon";
import AccordionArrowIcon from "./AccordionArrowIcon";
import LocationIcon from "./LocationIcon";
import ReadInfoIcon from "./ReadInfoIcon";
import RemoveIcon from "./RemoveIcon";
import FailIcon from "./FailIcon";
import VisaIcon from "./VisaIcon";
import ArrowDown from "./ArrowDown";
import LangIcon from "./LangIcon";
import WalletIcon from "./WalletIcon";
import LogoutIcon from "./LogoutIcon";
import Star from "./Star";
import Coins from "./Coins";
import CalendarSvg from "./CalendarSvg";
import MyAccs from "./MyAccs";
import CloseGreen from "./CloseGreen";
import SosIcon from "./SosIcon";
import DocumentIcon from "./DocumentIcon";
import CreditCard from "./CreditCard";
import Salary from "./Salary";
import LogoHalyk from "./LogoHalyk";
import Download from "./Download";
import ChangeCard from "./ChangeCard";
import ChangePolicy from "./ChangePolicy";
import ExtendPolicy from "./ExtendPolicy";
import Stop from './Stop'
import SwitchTariff from "./SwitchTariff";
import ToWithdraw from "./ToWithdraw";
import CloseWhiteButtonIcon from "./CloseWhiteButtonIcon";
import Spinner from "./Spinner";

export default {
  name: "Icon",
  props: {
    iconName: String
  },
  components: {
    ActiveHomeIcon,
    ArrowDown,
    HomeIconOne,
    AgreementIcon,
    ActiveAgreementIcon,
    MenuIcon,
    ActiveMenuIcon,
    ChatIcon,
    ActiveChatIcon,
    HomeBankIcon,
    PhoneIcon,
    HomeIconTwo,
    CarIcon,
    PersonIcon,
    AirplaneIcon,
    DownloadIcon,
    BackArrowIcon,
    PlusIcon,
    SelectArrowIcon,
    CloseButtonIcon,
    CloseModalIcon,
    CalendarIcon,
    MasterCardIcon,
    HalykIcon: () => import("./HalykIcon"),
    SuccessIcon,
    RatingStar,
    UmbrellaIcon,
    InfoIcon,
    GiftIcon,
    BonusIcon,
    WhatsappIcon,
    MessageIcon,
    ContractIcon,
    HeartIcon,
    ClipIcon,
    BookIcon,
    SendMessageIcon,
    AccordionArrowIcon,
    LocationIcon,
    ReadInfoIcon,
    RemoveIcon,
    FailIcon,
    VisaIcon,
    LangIcon,
    WalletIcon,
    LogoutIcon,
    Star,
    Coins,
    CalendarSvg,
    ChangeCard,
    ChangePolicy,
    ExtendPolicy,
    Stop,
    SwitchTariff,
    ToWithdraw,
    MyAccs,
    CloseGreen,
    SosIcon,
    DocumentIcon,
    CreditCard,
    Salary,
    LogoHalyk,
    Download,
    CloseWhiteButtonIcon,
    Spinner
  }
}
</script>

<style scoped>

</style>
